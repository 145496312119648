import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import "./councilsafe-overview.scss"
import Layout from "../layouts/default"
import SEO from "../components/seo"
import Section from "../components/structural/section"
import Form from "../components/paragraph/form"

export default () => {
  const data = useStaticQuery(graphql`
    {
      backgroundImage: file(relativePath: { eq: "councilsafe-overview.jpg" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const id = "page-councilsafe"
  const form = {
    field_form_id: "c23addc8-2fa5-ea11-a812-000d3a6a398b",
    field_form_id_2: "d6Ujn-a1WnLu5yKyJ5cgEb-lGyxnW9ACLkMB0oh2uso8",
  }

  return (
    <Layout id={id}>
      <SEO
        title={"CouncilSafe Overview"}
        image={data.backgroundImage.publicURL}
      />

      <Section
        id={`${id}__section`}
        className={`feed`}
        background_image={data.backgroundImage}
      >
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={10} xl={8}>
              <div className="title">
                <h1>Webinar: CouncilSafe Overview</h1>
                <h3>Thursday 25th of June @ 1pm AEST</h3>
              </div>
              <div className="content">
                <p>
                  We’ve re-imagined workplace safety for Local Government and
                  packaged industry leading software to provide an end to end
                  solution to protect Council workers and Contractors as a
                  turnkey solution.
                </p>
                <p>Register you details below to learn more.</p>
              </div>
              <div className="form">
                <Form props={form} />
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}
